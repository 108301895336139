import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const unbundleDevice = async params => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.put(`/activeCode/unbundle`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
