import React from "react"
import { Table, message, Tag, Modal } from "antd"

import { getActiveCodeOrderList } from "../../api/order"
import { unbundleDevice } from "../../api/activeCode"
import Layout from "../../layouts"

import "./active-code.css"

class ActiveCodeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initOrderList()
  }

  initOrderList = async () => {
    const res = await getActiveCodeOrderList()
    const data = res.data.data.activeCodeList
    if (data) {
      this.setState({ data })
    } else {
      message.error("加载激活码列表出错")
    }
  }

  handleUnbundleDevice = async (item, row) => {
    Modal.confirm({
      title: "是否解绑该设备",
      okText: "确认",
      cancelText: "取消",
      onOk: async () => {
        const res = await unbundleDevice({
          deviceCode: item.deviceCode,
          activeCode: row.activeCode,
        })
        if (res.data.success) {
          message.success("解绑成功")
          this.initOrderList()
        }
      },
    })
  }

  render() {
    const { data } = this.state

    const columns = [
      {
        title: "激活码",
        dataIndex: "activeCode",
        key: "activeCode",
      },
      {
        title: "激活码过期时间",
        dataIndex: "endTime",
        key: "endTime",
        render: endTime => {
          let element = null
          if (new Date().getTime() > new Date(endTime).getTime()) {
            element = <Tag color="red">已过期</Tag>
          }
          return (
            <>
              {element}
              {endTime && endTime.slice(0, -9).replace("T", " ")}
            </>
          )
        },
      },
      {
        title: "有效期",
        dataIndex: "duration",
        key: "duration",
        render: duration => duration + "天",
      },
      {
        title: "可用设备",
        dataIndex: "deviceNum",
        key: "deviceNum",
        render: deviceNum => deviceNum + "台",
      },
      {
        title: "已绑定设备",
        dataIndex: "deviceList",
        key: "deviceList",
        render: (deviceList, row) => {
          if (deviceList) {
            return deviceList.map((item, index) => (
              <div key={index}>
                <p>{item.deviceInfo}</p>
                <a onClick={() => this.handleUnbundleDevice(item, row)}>解绑</a>
              </div>
            ))
          }
        },
      },
      {
        title: "订单ID",
        dataIndex: "orderId",
        key: "orderId",
      },
    ]
    return (
      <Layout isSpacerDisplay={true} title="激活码">
        <div className="mdnice-active-code-container">
          <div className="mdnice-active-code-table-container">
            <Table
              bordered
              columns={columns}
              dataSource={data.map((item, index) => ({
                ...item,
                key: index,
              }))}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ActiveCodeList
